import React from "react";
import classNames from "classnames";
import Image from "../../elements/Image";

const FooterSocial = ({ className, ...props }) => {
  const classes = classNames("footer-social", className);

  return (
    <div {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <a href="https://www.linkedin.com/company/nashglobal">
            <Image
              src={require("./../../../assets/images/linkedInLogo.png")}
              alt="linkedIn"
            />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/getnashglobal">
            <Image
              src={require("./../../../assets/images/twitterLogo.png")}
              alt="twitter"
            />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterSocial;
