import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import Button from "../elements/Button";
import { AiOutlineArrowRight } from "react-icons/ai";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames("features-tiles-inner section-inner p-0");

  // const tilesClasses = classNames(
  //   "tiles-wrap center-content",
  //   pushLeft && "push-left"
  // );

  // const toolsClasses = classNames("tools");

  const sectionHeader = {
    title: "Full-Service Finance Platform",
    paragraph: "All-in-one finance platform for all your financial flows",
  };

  const smoothScrollToSignUp = (e) => {
    e.preventDefault();
    const element = document.getElementById("sign-up");
    element.scrollIntoView({
      block: "start",
      behavior: "smooth", // smooth scroll
    });
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses} data-reveal-delay="200">
          <SectionHeader data={sectionHeader} className="center-content pt-8"  />
        </div>
        <div
          className="tools-inner-wrap center-content-desktop"
          data-reveal-delay="400"
        >
          <Image
            src={require("./../../assets/images/illustration-tools.svg")}
            alt="Financial tools"
          />
          <p className="tools-inner-desc">
            Nash gives your team all the finance tools necessary to build, grow
            and scale your borderless business.
          </p>
          <Button
            className="button-join"
            wideMobile
            onClick={smoothScrollToSignUp}
          >
            Join Nash Today
            <span className="button-arrow">
              <AiOutlineArrowRight />
            </span>
          </Button>
        </div>
      </div>
      <Image
        className="full-service-illustration"
        src={require("./../../assets/images/illustration-full-service.svg")}
        alt="Financial tools"
      />
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
