import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import { AiOutlineArrowDown, AiOutlineArrowRight } from "react-icons/ai";
// import Modal from "../elements/Modal";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  // const [videoModalActive, setVideomodalactive] = useState(false);

  const outerClasses = classNames(
    "hero section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const smoothScrollToSignUp = (e) => {
    e.preventDefault();
    const element = document.getElementById("sign-up");
    element.scrollIntoView({
      block: "start",
      behavior: "smooth", // smooth scroll
    });
  };

  const smoothScrollToNextContainer = (e) => {
    e.preventDefault();
    const element = document.getElementById("section-02");
    element.scrollIntoView({
      block: "start",
      behavior: "smooth", // smooth scroll
    });
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <Image
          className="illustration-cards"
          src={require("./../../assets/images/cards-with-glow.png")}
          alt="cards"
        />
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="hero-header mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              Modern Finance OS <br />
              for
              <div className="os-usecases rotate-usecases ">
                <span>Growing Businesses</span>
                <span>Medium Businesses</span>
                <span>Startups</span>
                <span>Fintechs</span>
                <span>Enterprises</span>
                <span>Financial Institutions</span>
                <span>Tech Companies</span>
              </div>
            </h1>
            <div className="container-xs-no-pad">
              <p
                className="reveal-from-bottom avenir-font hero-header-desc"
                data-reveal-delay="400"
                data-reveal-offset="50"
              >
                A single dashboard to <b>centralize all your finance flows</b>{" "}
                from<b> money accounts, payments, accounting </b>and
                <b> spend management.</b>
              </p>
              <div
                className="reveal-from-bottom section-1-button-wrapper"
                data-reveal-delay="600"
                data-reveal-offset="50"
              >
                <ButtonGroup className="home-buttons">
                  <Button
                    className="button-sign-up"
                    onClick={smoothScrollToSignUp}
                    wideMobile
                  >
                    Sign Up
                    <span className="button-arrow">
                      <AiOutlineArrowRight />
                    </span>
                  </Button>
                  <Button
                    className="button-book-demo"
                    tag="a"
                    wideMobile
                    href="https://calendly.com/nash-nashinsights"
                  >
                    Book Demo
                    <span className="button-arrow">
                      <AiOutlineArrowRight />
                    </span>
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>

          <div
            className="hero-figure reveal-from-bottom  illustration-element-01"
            data-reveal-offset="100"
            data-reveal-value="20px"
            data-reveal-delay="200"
          >
            <hr className="nash-hr-gradient" />
            <div className="investor-wrapper m-0">
              <div className="investor-desc">
                <p className="avenir-font mb-0 investor-desc-p">
                  Our investors have backed some of the best-in-class Fintechs{" "}
                </p>
              </div>
              <div>
                <Image
                  style={{ width: "100%", margin: "auto" }}
                  src={require("./../../assets/images/investors.svg")}
                  alt="investors"
                />
              </div>
            </div>
            <hr className="nash-hr-solid" />
          </div>
        </div>

        <button
          onClick={smoothScrollToNextContainer}
          className="nav-arrow-down"
        >
          <AiOutlineArrowDown />
        </button>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
