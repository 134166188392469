import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Logo from "./partials/Logo";

const myFunction = () => {
  var header = document.getElementById("header");

  if (window.pageYOffset >= 50) {
    header.classList.add("sticky-header");
  } else {
    header.classList.remove("sticky-header");
  }
};

const propTypes = {
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  bottomOuterDivider: false,
  bottomDivider: false,
};

const Header = ({ className, bottomOuterDivider, bottomDivider, ...props }) => {
  const classes = classNames(
    "site-header",
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  useEffect(() => {
    window.onscroll = function () {
      myFunction();
    };
  });

  return (
    <header {...props} className={classes} id="header">
      <div className="container">
        <div
          className={classNames(
            "site-header-inner",
            bottomDivider && "has-bottom-divider"
          )}
        >
          <Logo />
        </div>
      </div>
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
