import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Testimonial = ({
  className,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  pushLeft,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );



  const innerClasses = classNames("testimonial-inner section-inner");

  // const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    paragraph: "The Finance Platform Built for Your Borderless Business",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="center-content borderless-business-desc" > {sectionHeader.paragraph}</div>
          <div className="center-content-desktop">
            <Image
              src={require("./../../assets/images/flows.svg")}
              alt="Financial tools"
            />
          </div>
          
        </div>
      </div>
    </section>
  );
};

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
