/* eslint-disable */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Input from "../elements/Input";
import Image from "../elements/Image";
import Button from "../elements/Button";
import { useForm } from "react-hook-form";
import Select from "react-select";

//react select styling
const countryCodeSelectStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "40%",
    background: "transparent",
    border: "none",
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: "#636363",
    background: "transparent",
    fontFamily: "Poppins",
    fontSize: "15px !important",
    paddingLeft: "15px",
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    width: "0px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#000000",
  }),
  control: (provided, state) => ({
    ...provided,
    padding: "15px",
    background: "transparent",
    border: "none",
    boxShadow: "none",
    height: "100%",
    ":hover": {
      border: "none",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    color: "#ffffff",
    background: "#000000",
    fontFamily: "Poppins",
    fontSize: "15px",
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isFocused ? "#000000" : "#000000",
  }),
};

const customSelectStyles = {
  indicatorSeparator: (provided, state) => ({
    ...provided,
    width: "0px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#000000",
  }),

  container: (provided, state) => ({
    ...provided,
    background: "#d9d9d9",
    height: "48px",
    marginBottom: "24px",
    borderRadius: "5px",
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: "#636363",
    background: "transparent",
    fontFamily: "Poppins",
    fontSize: "15px !important",
    paddingLeft: "15px",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    color: "#636363",
    background: "transparent",
    fontFamily: "Poppins",
    fontSize: "15px !important",
    paddingLeft: "15px",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    ":hover": {
      background: "transparent",
      color: "red",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    background: "transparent",
    border: "none",
    boxShadow: "none",
    height: "100%",
    ":hover": {
      border: "none",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    color: "#ffffff",
    background: "#000000",
    fontFamily: "Poppins",
    fontSize: "15px",
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isFocused ? "#000000" : "#000000",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#636363",
    fontFamily: "Poppins",
    fontSize: "15px",
    paddingLeft: "15px",
  }),
};

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  invertDesktop,
  invertMobile,
  alignTop,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  // const [countryDialPrefixList, setCountryDialPrefixList] = useState([]);
  const [companyType, setCompanyType] = useState("");
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [interest, setInterest] = useState("");

  const countries = require("./../../assets/fetchedcountries.json");
  let countryCodeNFlag = require("./../../assets/dialPrefixes.json");
  let countryDialPrefixList = [];

  countryCodeNFlag.forEach((country) => {
    let countryData = {};
    countryData.value = country.value;
    countryData.label = (
      <div className="country-code-select">
        <Image className="country-flag-select" src={country.flag} />
        <span>{country.value}</span>
      </div>
    );

    countryDialPrefixList.push(countryData);
  });

  const outerClasses = classNames(
    "cta section center-content-mobile reveal-from-bottom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setLoading(true);
    data.workEmail = data.email;
    data.companyType = companyType;
    data.country = country;
    data.phoneNumber = countryCode + parseInt(data.phoneNumber);
    data.interestTypes = interest;
    data.message = "";

    postData("https://staging.treasury.nashglobal.co/api/NashSignups", data);
  };

  const postData = (url = "", data = {}) => {
    const response = fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        alert("You will receive an email shortly");
        setTimeout(() => {
          smoothScrollTop();
        }, 2000);
      } else {
        alert("Something went wrong, please try again or contact support");
      }
    });
  };

  const smoothScrollTop = () => {
    const element = document.getElementById("section-01");
    element.scrollIntoView({
      block: "start",
      behavior: "smooth", // smooth scroll
    });
  };

  const companyTypeHandler = (selection) => {
    setCompanyType(selection.value);
  };

  const countryHandler = (selection) => {
    setCountry(selection.value);
  };

  const countryCodeHandler = (selection) => {
    setCountryCode(selection.value);
  };

  const interestHandler = (selections) => {
    console.log(selections);
    let interestTypes = [];
    selections.forEach((selection) => {
      interestTypes.push(selection.value);
    });
    setInterest(interestTypes);
  };

  const companyTypes = [
    { value: "startup", label: "Startup" },
    { value: "scaleup", label: "Scaleup" },
    { value: "enterprise", label: "Enterprise" },
    { value: "mediumBusiness", label: "Medium Business" },
    { value: "fintech", label: "Fintech" },
  ];

  const industries = [
    { value: "soleProprietorship", label: "Sole Proprietorship" },
    { value: "partnership", label: "Partnership" },
    { value: "corporation", label: "Corporation" },
    { value: "llc", label: "Limited Liability Company" },
  ];

  const interests = [
    { value: "product", label: "Product" },
    { value: "press", label: "Press" },
    { value: "partnership", label: "Partnership" },
  ];

  let defaultCountryCodeIndex;

  if (countries.length > 0) {
    defaultCountryCodeIndex = countryDialPrefixList.findIndex((country) => {
      return country.value == "+254";
    });
  }

  return (
    <section {...props} className={outerClasses}>
      <Image
        className="shadowy-form"
        src={require("./../../assets/images/illustration-cta.svg")}
        alt="shadow"
      />
      <div className="container p-0">
        <div className={innerClasses}>
          <div className={splitClasses}>
            <div className="split-item ">
              <div
                className="split-item-content center-content-mobile"
                data-reveal-container=".split-item"
                data-reveal-delay="200"
              >
                <h3 className="mt-0 mb-0 align-left">One Platform</h3>
                <h4 className="m-0 align-left">
                  One Place to Manage
                  <div className="scrolling-features scroll-features">
                    <span>Invoices</span>
                    <span>Receipts </span>
                    <span>Bank Payments </span>
                    <span>Approvals </span>
                    <span>Controls </span>
                    <span>Vendors </span>
                    <span>Customers </span>
                    <span>Subscriptions </span>
                    <span>Benefits </span>
                    <span>Issue Cards </span>
                    <span>Sync Accounting</span>
                    <span>Bill Payments </span>
                  </div>
                </h4>
                <Image
                  src={require("./../../assets/images/one-platform.svg")}
                  alt="Financial tools"
                />
              </div>
            </div>
            <div className="split-item ">
              <div className="split-item-content align-left reveal-from-left">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="cta-form p-32"
                >
                  <div className="cta-form-name-wrapper mb-24">
                    <input
                      placeholder={`${
                        errors.firstName
                          ? "* Enter your first name"
                          : "First Name"
                      }`}
                      id="firstName"
                      type="text"
                      className="form-input cta-form-name-input"
                      {...register("firstName", { required: true })}
                    />
                    <input
                      placeholder={`${
                        errors.firstName
                          ? "* Enter your last name"
                          : "Last Name"
                      }`}
                      id="lastName"
                      type="text"
                      className="form-input cta-form-name-input"
                      {...register("lastName", { required: true })}
                    />
                  </div>

                  <input
                    placeholder={`${
                      errors.companyName
                        ? "* Enter your company's name"
                        : "Company Name"
                    }`}
                    id="companyName"
                    type="text"
                    {...register("companyName", { required: true })}
                    className="form-input form-input mb-24"
                  />
                  <Select
                    styles={customSelectStyles}
                    onChange={companyTypeHandler}
                    placeholder="Company Type"
                    id="companyType"
                    name="companyType"
                    options={companyTypes}
                  />
                  <span className="validation-error">{errors.email && errors.email?.message}</span>
                  <input
                    placeholder={`${
                      errors.email ? errors.email?.message : "Work Email"
                    }`}
                    id="email"
                    type="text"
                    className="form-input mb-24"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "* Enter your work email address",
                      },
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "* Enter a valid email address",
                      },
                    })}
                  />
                  <Select
                    styles={customSelectStyles}
                    onChange={countryHandler}
                    defaultValue={countries[102]}
                    id="country"
                    name="country"
                    options={countries}
                  />

                  <div className="form-input country-code mb-24">
                    <Select
                      onChange={countryCodeHandler}
                      styles={countryCodeSelectStyles}
                      labelHidden
                      defaultValue={countryDialPrefixList[102]}
                      id="countryCode"
                      name="countryCode"
                      type="text"
                      options={countryDialPrefixList}
                    />
                    <input
                      placeholder={`${
                        errors.phoneNumber
                          ? "* Enter a valid phone number"
                          : "Phone Number"
                      }`}
                      id="phoneNumber"
                      className="phone-number-Input"
                      type="text"
                      {...register("phoneNumber", {
                        required: {
                          value: true,
                          message: "* Enter your phone number",
                        },
                        pattern: {
                          value: /^[0-9]{10,13}$/,
                          message: "* Invalid phone number",
                        },
                      })}
                    />
                  </div>

                  <Select
                    onChange={interestHandler}
                    styles={customSelectStyles}
                    isMulti
                    labelHidden
                    placeholder="Interest"
                    id="interest"
                    name="interest"
                    type="text"
                    options={interests}
                  />
                  <Input
                    labelHidden
                    placeholder="Message (Optional)"
                    id="message"
                    type="textarea"
                    rows={3}
                    className="form-input mb-24"
                  />
                  <br />
                  <Button className="button-sign-up-form">
                    {loading ? "Submitting..." : "Sign Up"}
                  </Button>
                  <p className="disclaimer pt-32 m-0">
                    *We’ll only use your information to contact you for purposes
                    of Nash and the Nash products.
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cta-bottom-illustration">
        <Image
          src={require("./../../assets/images/cta-illustration.svg")}
          alt="wavy"
        />
      </div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
