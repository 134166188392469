import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Logo from "./partials/Logo";
// import FooterNav from "./partials/FooterNav";
import FooterSocial from "./partials/FooterSocial";
import { AiOutlineArrowUp } from "react-icons/ai";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
  const classes = classNames(
    "site-footer center-content-mobile",
    topOuterDivider && "has-top-divider",
    className
  );

  const smoothScrollTop = (e) => {
    e.preventDefault();
    const element = document.getElementById("section-01");
    element.scrollIntoView({
      block: "start",
      behavior: "smooth", // smooth scroll
    });
  };

  return (
    <footer {...props} className={classes}>
      <hr className="m-0" />
      <div className="container">
        <div className={classNames("site-footer-inner")}>
          <div className="footer-left space-between text-xxs">
            <Logo className="mb-16" />
            <FooterSocial className="mb-16" />
            <div className="footer-copyright mb-8">© 2022 NashEQ, Inc</div>
            <div className="footer-copyright" style={{ color: "#ffffff" }}>
              Contact us:{" "}
              <a className="nash-email" href="mailto::info@getnashglobal.com">
                info@getnashglobal.com
              </a>
            </div>
          </div>
          {/* <div className="footer-right space-between text-xxs invert-order-desktop">
            <div
              className="avenir-font contact-us"
              style={{ color: "#ffffff" }}
            >
              Contact us: info@nashafrica.co{" "}
            </div>
          </div> */}
        </div>
        <hr className="m-0" />
        <p className="footer-disclaimer">
          All product and company names are trademarks or registered trademarks
          of their respective holders. Use of them does not imply any
          affiliation with or endorsement by them.
        </p>
        <button onClick={smoothScrollTop} className="footer-nav-arrow-top">
          <AiOutlineArrowUp />
        </button>
      </div>
    </footer>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
